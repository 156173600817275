<template>
	<div class="container">
		<header-view ref="header" :class="{'on': minBar}" :showMenu="showMenu" :page="3" @change="changeMenu"></header-view>
		<right-tool :class="{'show': btnFlag}" :isEmit="true" @topTap="topTap"></right-tool>
		<div class="full-wrap">
			<full-page :options="options" id="fullpage" ref="fullpage">
				<div class="section">
					<div class="pic">
						<img class="animated picscall" src="../../assets/images/o-banner.png" />
					</div>
					<div class="ind_hint_wrap flex-col">
						<div class="ind_hint_icon animate__animated animate__fadeInUp" style="animation-delay: .2s;"><div></div></div>
						<div class="ind_hint ft-12 animate__animated animate__fadeInUp" style="animation-delay: .4s;">SCROLL DOWN</div>
					</div>
					<div class="topic">
						<div class="txt_ flex-col">
							<h1 class="ft-64 animate__animated animate__fadeInDown" style="visibility: visible; animation-delay: .2s;">
								<span>
									<em class="animated fadeInDownSmall" style="visibility: visible; animation-delay: 0s;">与</em>
								</span>
								<span>
									<em class="animated fadeInDownSmall" style="visibility: visible; animation-delay: 0.1s;">我</em>
								</span>
								<span>
									<em class="animated fadeInDownSmall" style="visibility: visible; animation-delay: 0.2s;">共</em>
								</span>
								<span>
									<em class="animated fadeInDownSmall" style="visibility: visible; animation-delay: 0.3s;">精</em>
								</span>
								<span>
									<em class="animated fadeInDownSmall" style="visibility: visible; animation-delay: 0.4s;">彩</em>
								</span>
							</h1>
							<small class="ft-20 animate__animated animate__fadeInUp" style="visibility: visible; animation-delay: .6s;">
								<span>
									<em class="animate__animated animate__fadeInUp" style="visibility: visible; animation-delay: 0s;">Be</em>
								</span>
								<span>
									<em class="animate__animated animate__fadeInUp" style="visibility: visible; animation-delay: 0.2s;">Wonderful</em>
								</span>
								<span>
									<em class="animate__animated animate__fadeInUp" style="visibility: visible; animation-delay: 0.4s;">with</em>
								</span>
								<span>
									<em class="animate__animated animate__fadeInUp" style="visibility: visible; animation-delay: 0.6s;">me</em>
								</span>
							</small>
						</div>
					</div>
				</div>
				<div class="section other">
					<div class="topic">
						<div class="txt_ flex-col">
							<h1 class="ft-42 sub animate__animated" style="animation-delay: 0s!important;">
								<span>
									<em>影</em>
								</span>
								<span>
									<em>视</em>
								</span>
								<span>
									<em>剧</em>
								</span>
								<span>
									<em>本</em>
								</span>
								<span>
									<em>调</em>
								</span>
								<span>
									<em>研</em>
								</span>
							</h1>
							<small class="ft-14 animate__animated" style="animation-delay: .1s!important;">
								<span>
									<em>Film</em>
								</span>
								<span>
									<em>and</em>
								</span>
								<span>
									<em>TV</em>
								</span>
								<span>
									<em>Drama</em>
								</span>
								<span>
									<em>Scripts</em>
								</span>
								<span>
									<em>Research</em>
								</span>
							</small>
							<div class="details ft-16 animate__animated" style="animation-delay: .2s!important;">
								<p>拥有专业影视调研团队，依托海量剧本资源，层层筛选剧本；以敏锐的影视市场嗅觉，寻找故事线索和故事情节，挖掘可做影视改编的文</p>
								<p>学作品；合作专业大数据分析平台，调研影视作品拍摄成本、制作类型、市场、观众群、票房等，提供理性、客观的影视项目评估方案，</p>
								<p>孵化符合市场发展趋势的优质影视项目。</p>
							</div>
						</div>
						<div class="item-wrap">
							<div class="item-title animate__animated" style="animation-delay: .5s!important;">
								<span class="ft-24">项目优势</span>
							</div>
							<div class="item-list t1 flex-row">
								<div class="item flex-col">
									<div class="top flex-row animate__animated">
										<img src="../../assets/images/o-team.png" />
									</div>
									<div class="text animate__animated">
										<h1 class="ft-20">专业团队</h1>
										<p class="ft-14">专业影视调研团队</p>
									</div>
								</div>
								<div class="item flex-col">
									<div class="top flex-row animate__animated">
										<img src="../../assets/images/o-xl.png" />
									</div>
									<div class="text animate__animated">
										<h1 class="ft-20">精准效率</h1>
										<p class="ft-14">精准狙击优质内容，定位</p>
										<p class="ft-14">剧本开发方向</p>
									</div>
								</div>
								<div class="item flex-col">
									<div class="top flex-row animate__animated">
										<img src="../../assets/images/o-fx.png" />
									</div>
									<div class="text animate__animated">
										<h1 class="ft-20">深度分析</h1>
										<p class="ft-14">合作专业大数据分析平台</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="pic">
						<img src="../../assets/images/o-banner4.png" />
					</div>
				</div>
				<div class="section other">
					<div class="topic">
						<div class="txt_ flex-col">
							<h1 class="ft-42 sub animate__animated" style="animation-delay: 0s!important;">
								<span>
									<em>影</em>
								</span>
								<span>
									<em>视</em>
								</span>
								<span>
									<em>制</em>
								</span>
								<span>
									<em>作</em>
								</span>
							</h1>
							<small class="ft-14 animate__animated" style="animation-delay: .1s!important;">
								<span>
									<em>Film</em>
								</span>
								<span>
									<em>and</em>
								</span>
								<span>
									<em>TV</em>
								</span>
								<span>
									<em>Production</em>
								</span>
							</small>
							<div class="details ft-16 animate__animated" style="animation-delay: .2s!important;">
								<p>汇聚了专业的内容团队、影视行业资深制片人、监制、制作团队、演员多方深度协作，保证项目进度及品质。为影片提供全方位的宣传包</p>
								<p>装，定制传播策略、媒介投放、营销事件、落地执行等系统的全媒体整合传播渠道，打造更贴切中国电影市场一流水准的专业化高端影视</p>
								<p>产品。实现全营销业务的融合贯通，为客户创造更大的价值。</p>
							</div>
						</div>
						<div class="item-wrap">
							<div class="item-title animate__animated" style="animation-delay: .5s!important;">
								<span class="ft-24">项目优势</span>
							</div>
							<div class="item-list t1 flex-row">
								<div class="item flex-col">
									<div class="top flex-row animate__animated">
										<img src="../../assets/images/t2.png" />
									</div>
									<div class="text animate__animated">
										<h1 class="ft-20">矩阵化</h1>
										<p class="ft-14">优质版权采购，自研影视项目</p>
									</div>
								</div>
								<div class="item flex-col">
									<div class="top flex-row animate__animated">
										<img src="../../assets/images/t3.png" />
									</div>
									<div class="text animate__animated">
										<h1 class="ft-20">专业化</h1>
										<p class="ft-14">行业制片人、监制、演员多方协作</p>
										<p class="ft-14">保证项目进度及品质</p>
									</div>
								</div>
								<div class="item flex-col">
									<div class="top flex-row animate__animated">
										<img src="../../assets/images/t1.png" />
									</div>
									<div class="text animate__animated">
										<h1 class="ft-20">价值化</h1>
										<p class="ft-14">携手优势资源，研发制作</p>
										<p class="ft-14">全球影视项目</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="pic">
						<img src="../../assets/images/o-banner1.png" />
					</div>
				</div>
				<div class="section other">
					<div class="topic">
						<div class="txt_ flex-col">
							<h1 class="ft-42 sub animate__animated" style="animation-delay: 0s!important;">
								<span>
									<em>影</em>
								</span>
								<span>
									<em>视</em>
								</span>
								<span>
									<em>宣</em>
								</span>
								<span>
									<em>发</em>
								</span>
							</h1>
							<small class="ft-14 animate__animated" style="animation-delay: .1s!important;">
								<span>
									<em>Film</em>
								</span>
								<span>
									<em>and</em>
								</span>
								<span>
									<em>TV</em>
								</span>
								<span>
									<em>Promotion</em>
								</span>
								<span>
									<em>and</em>
								</span>
								<span>
									<em>Distribution</em>
								</span>
							</small>
							<div class="details ft-16 animate__animated" style="animation-delay: .2s!important;">
								<p>秉持国际化制作营销理念，为影片全程护航。同时，与优秀渠道和平台的成功且长期的合作，助力我们建立了庞大的宣发资源网络，为打</p>
								<p>造优质的网络剧和网络大电影奠定基础。 业务包括提供前瞻、框架性的项目评估； 为合作伙伴提供有效、性价比高的宣传服务， 多维度</p>
								<p>助推网生内容的生产及传播等</p>
							</div>
						</div>
						<div class="item-wrap">
							<div class="item-title animate__animated" style="animation-delay: .5s!important;">
								<span class="ft-24">推广资源覆盖</span>
							</div>
							<div class="item-list t2 flex-row">
								<div class="item flex-col" v-for="(item,index) in partnerList" :key="index">
									<div class="top flex-row animate__animated">
										<img :src="item.imgUrl" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="pic">
						<img src="../../assets/images/o-banner2.png" />
					</div>
				</div>
				<div class="section other">
					<div class="topic">
						<div class="txt_ flex-col">
							<h1 class="ft-42 sub animate__animated" style="animation-delay: 0s!important;">
								<span>
									<em>影</em>
								</span>
								<span>
									<em>视</em>
								</span>
								<span>
									<em>视</em>
								</span>
								<span>
									<em>效</em>
								</span>
							</h1>
							<small class="ft-14 animate__animated" style="animation-delay: .1s!important;">
								<span>
									<em>Film</em>
								</span>
								<span>
									<em>and</em>
								</span>
								<span>
									<em>TV</em>
								</span>
								<span>
									<em>Visual</em>
								</span>
								<span>
									<em>Effects</em>
								</span>
							</small>
							<div class="details ft-16 animate__animated" style="animation-delay: .2s!important;">
								<p>完善的影视视效制作团队，汇聚模型、绑定、灯光、渲染、合成、特效各方面顶尖人才，参与制作《黄沙渡·刀客漠途》《深渊异兽》</p>
								<p>等影视项目。动画，影视全流程制作服务，接受各种影视后期有视觉特效需求的项目委托</p>
							</div>
						</div>
						<div class="item-wrap">
							<div class="item-title animate__animated" style="animation-delay: .5s!important;">
								<span class="ft-24">部分案例</span>
							</div>
							<div class="item-list t3 flex-row">
								<div class="item flex-col">
									<div class="top flex-row animate__animated">
										<img src="../../assets/images/case1.png" />
									</div>
								</div>
								<div class="item flex-col">
									<div class="top flex-row animate__animated">
										<img src="../../assets/images/case2.png" />
									</div>
								</div>
								<div class="item flex-col">
									<div class="top flex-row animate__animated">
										<img src="../../assets/images/case3.png" />
									</div>
								</div>
								<div class="item flex-col">
									<div class="top flex-row animate__animated">
										<img src="../../assets/images/case4.png" />
									</div>
								</div>
								<div class="item flex-col">
									<div class="top flex-row animate__animated">
										<img src="../../assets/images/case5.png" />
									</div>
								</div>
								<div class="item flex-col">
									<div class="top flex-row animate__animated">
										<img src="../../assets/images/case6.png" />
									</div>
								</div>
								<div class="item flex-col">
									<div class="top flex-row animate__animated">
										<img src="../../assets/images/case7.png" />
									</div>
								</div>
								<div class="item flex-col">
									<div class="top flex-row animate__animated">
										<img src="../../assets/images/case8.png" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="pic">
						<img src="../../assets/images/o-banner3.png" />
					</div>
				</div>
				<div class="section other contact_footer">
					<div class="topic">
						<div class="txt_ flex-col">
							<h1 class="ft-40 animate__animated" style="animation-delay: 0s!important;">
								<span>
									<em>合</em>
								</span>
								<span>
									<em>作</em>
								</span>
								<span>
									<em>洽</em>
								</span>
								<span>
									<em>谈</em>
								</span>
							</h1>
							<div class="details ft-16 animate__animated" style="animation-delay: .2s!important;">
								<p>如果您或您的企业想要与我们进行商务层面的洽谈，寻求内容共建、媒体推广、业务拓展等</p>
								<p>方面的合作，欢迎通过以下方式联系我们</p>
							</div>
							<div class="tel-wrap flex-row">
								<div class="tel-item flex-row animate__animated">
									<div class="left ft-36">01</div>
									<div class="right ft-16 flex-col">
										<p>联系电话：</p>
										<p>15280605328</p>
									</div>
								</div>
								<div class="tel-item flex-row animate__animated">
									<div class="left ft-36">02</div>
									<div class="right ft-16 flex-col">
										<p>项目资料请发送邮箱至：</p>
										<p>2545817883@qq.com</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="pic">
						<img src="../../assets/images/o-footer.png" />
					</div>
					<div class="bottom">
						<div class="w80 flex-row animate__animated">
							<p class="ft-12">浩亿影业 : www.haoyifilmgroup.com 版权所有 | <a href="https://beian.miit.gov.cn" target="_blank">闽ICP备18025946号-1</a></p>
						</div>
					</div>
				</div>
			</full-page>
		</div>
	</div>
</template>
<script>
	import headerView from '@/components/header/headerView.vue'
	import rightTool from '@/components/rightTool/index.vue'
	import { partnerList } from '@/api/api.js'
	import $ from 'jquery'
	export default {
		components: {
			headerView,
			rightTool
		},
		data() {
			return {
				showMenu: false,
				minBar: false,
				btnFlag: false,
				partnerList: [],
				options: {
					licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
					afterLoad: this.afterLoad,
					navigation: true,
					scrollingSpeed: 1000,
					css3: true,
				}
			};
		},
		created() {
			this.getPartnerList()
		},
		methods: {
			topTap() {
				this.$refs.fullpage.api.moveTo(1)
			},
			changeMenu(bool) {
				this.showMenu = !bool
			},
			afterLoad(anchorLink, index) {
				if (index.index === 0) {
					this.showMenu = false
				} else {
					this.showMenu = true
				}
				if ($(index.item).hasClass('active') && index.index !== 0) {
					this.btnFlag = true
					this.setAnimate('.section.active .topic .animate__animated','animate__fadeInUp')
					this.setAnimate('.section.active.contact_footer .animate__animated','animate__fadeInUp')
				} else {
					this.btnFlag = false
				}
			},
			getPartnerList() {
				partnerList({pageNo:1,pageSize:20}).then(res =>{
					if (res.success) {
						this.partnerList = res.result.records
					}
				})
			},
			setAnimate(dom,animateName) {
				if (!$(dom).length) return
				if ($(dom).length <= 1) {
					let domHeight = $(window).height() - ($(dom).offset().top - $(document).scrollTop()) - $(dom).height()
					if (($(window).height() - domHeight) > $(window).height() * 0.5) {
						$(dom).css("visibility","visible");
						$(dom).addClass(animateName)
					}					
				} else {
					var domList = $(dom)
					let domHeight = [];
					for(let i = 0; i < domList.length; i++) {
						domHeight[i] = $(window).height() - ($(domList[i]).offset().top - $(document).scrollTop()) - $(domList[i]).height()
						// console.log($(window).height() + domHeight[i])
						// if (($(window).height() + domHeight[i]) > $(window).height() * 0.5) {
							if ((i+1) < 10) {
								$(domList[i]).css({"visibility":"visible","animation-delay": "0."+(i+1)+"s"});
							} else {
								$(domList[i]).css({"visibility":"visible","animation-delay": "1."+(i+1)+"s"});
							}
							$(domList[i]).addClass(animateName)
						// }	
					}
				}
			},
		}
	};
</script>
<style lang="scss" scoped>
	.container{
		.full-wrap{
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: 100vh;
			.fullpage-wrapper{
				width: 100%;
				height: 100vh!important;
				.section{
					position: relative;
					width: 100%;
					height: 100%;
					&.contact_footer{
						height: 55vh!important;
						/deep/ .fp-tableCell{
							.pic{
								position: absolute;
								width: 100%;
								height: calc(100% - 8vh)!important;
								left: 0;
								top: 0;
								img{
									display: block;
									width: 100%;
									height: 100%;
									object-fit: cover;
									display: block;
									transition: all 3s ease;
								}
							}
							.topic{
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: calc(100% - 8vh);
							}
							.bottom {
								position: absolute;
								bottom: 0;
								width: 100%;
								height: 8vh;
								visibility: hidden;
								&::before {
									position: absolute;
									left: 0;
									top: 0;
									content: '';
									width: 100%;
									height: 1px;
									background-color: #3F4D6C;
									opacity: 0.2477;
								}
							
								.w80 {
									width: 80%;
									margin: 0 auto;
									height: 100%;
									justify-content: flex-start;
							
									p {
										color: #6B6B6B;
										animation-delay: .8s;
										a{
											color: #6B6B6B;
										}
									}
								}
							}
						}
					}
					/deep/ .fp-tableCell{
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%!important;
						display: flex;
						justify-content: center;
						align-items: center;
						.pic{
							position: absolute;
							width: 100%;
							height: 100%;
							left: 0;
							top: 0;
							overflow: hidden;
							img{
								display: block;
								width: 100%;
								height: 100%;
								object-fit: cover;
								display: block;
								transition: all 3s ease;
							}
						}
						.ind_hint_wrap{
							position: absolute;
							bottom: 0;
							left: 0;
							padding: 30px 0;
							width: 100%;
							.ind_hint{
								color: #FFFFFF;
							}
							.ind_hint_icon{
								position: relative;
								width: 16px;
								height: 26px;
								border: 2px solid #fff;
								border-radius: 8px;
								padding: 4px 0;
								margin-bottom: 10px;
								div{
									height: 6px;
									width: 2px;
									background-color: #fff;
									border-radius: 2px;
									position: absolute;
									top: 6px;
									left: 50%;
									margin-left: -1px;
									animation: gun0 1s ease infinite;
								}
							}
						}
						.topic {
							position: relative;
							z-index: 10;
							width: 100%;
							text-align: center;
							display: flex;
							align-items: center;
							justify-content: center;
							flex-direction: column;
								.animate__animated{
									color: #fff;
									visibility: hidden;
								}
							.txt_ {
								min-width: 50vw;
								max-width: 100vw;
								margin-bottom: 2vh;
								h1 {
									&.sub{
										line-height: 4vh;
									}
									line-height: 60px;
									letter-spacing: 4px;
									span > em {
										font-weight: 400;
									}
								}
								small {
									display: block;
									line-height: 40px;
									height: 40px;
									letter-spacing: 1px;
									span {
										margin: 0 4px;
										font-size: inherit;
										color: inherit;
										font-weight: inherit;
										font-family: inherit;
										line-height: 1.2;
										overflow: hidden;
										vertical-align: middle;
									}
								}
								.details{
									margin-top: 5vh;
									p{
										line-height: 28px;
									}
								}
								.tel-wrap{
									min-width: 50vw;
									margin-top: 6vh;
									justify-content: space-around;
									color: #FFF;
									.tel-item{
										visibility: hidden;
										.left{
											color: #666666;
										}
										.right{
											padding-left: 1vw;
											align-items: flex-start;
											p{
												&:last-child{
													margin-top: 2px;
													color: #EA4738;
												}
											}
										}
									}
									
								}
							}
							.item-wrap{
								margin-top: 3vh;
								min-width: 60vw;
								max-width: 70vw;
								color: #FFF;
								.item-title{
									margin-bottom: 5vh;
									span{
										position: relative;
										padding: 0 20px;
										&::before,&::after{
											content: '';
											width: 70px;
											height: 1px;
											background-color: rgba(255, 255, 255, .6);
											position: absolute;
											top: 50%;
										}
										&::before{
											left: -80px;
										}
										&::after{
											right: -80px;
										}
									}
								}
								.item-list{
									&.t1{
										justify-content: space-around;
										align-items: flex-start;
										.item{
											justify-content: flex-start;
											width: 30%;
											.top{
												width: 7vw;
												height: 7vw;
												border: 1px dashed rgba(255,255,255,.7);
												border-radius: 50%;
												visibility: hidden;
												img{
													width: 3vw;
												}
											}
											.text{
												visibility: hidden;
												h1{
													margin: 3vh 0 1vh 0;
												}
												p{
													line-height: 22px;
												}
											}
										}
									}
									&.t2{
										width: 90%;
										margin: 0 auto -8vh;
										flex-wrap: wrap;
										justify-content: flex-start;
										.item{
											width: 25%;
											margin-bottom: 5vh;
											.top{
												width: 100%;
												img{
													width: 70%;
												}
											}
										}
									}
									&.t3{
										width: 60vw;
										flex-wrap: wrap;
										justify-content: flex-start;
										.item{
											width: 25%;
											margin-bottom: 3vh;
											.top{
												width: 90%;
												img{
													width: 100%;
												}
											}
										}
									}
								}
								@media (max-width: 1440px){
									.item-list{
										&.t1{
											justify-content: space-around;
											align-items: flex-start;
											margin: 0 auto -8vh;
											.item{
												justify-content: flex-start;
												width: 30%;
												.top{
													width: 5vw;
													height: 5vw;
													border: 1px dashed rgba(255,255,255,.7);
													border-radius: 50%;
													visibility: hidden;
													img{
														width: 2.5vw;
													}
												}
												.text{
													visibility: hidden;
													h1{
														margin: 1.5vh 0 .5vh 0;
													}
													p{
														line-height: 22px;
													}
												}
											}
										}
										&.t3{
											width: 50vw;
											margin: 0 auto -8vh;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
</style>